<template>
  <article class="article">
    <section class="article__text article--bg-corporate">
      <h1>{{ $t('privacy.title') }}</h1>
      <p>{{ $t('privacy.intro') }}</p>

      <h2>{{ $t('privacy.responsibilities.title') }}</h2>
      <p v-html="$t('privacy.responsibilities.text')" class="consent"></p>

      <div class="section">
        <h2>{{ $t('privacy.processing.title') }}</h2>
        <h3>{{ $t('privacy.processing.subtitle_1') }}</h3>
        <div v-html="$t('privacy.processing.text_1')" class="consent"></div>

        <h3>{{ $t('privacy.processing.subtitle_2') }}</h3>
        <div v-html="$t('privacy.processing.text_2')" class="consent"></div>
      </div>

      <div class="section">
        <h2>{{ $t('privacy.features.title') }}</h2>
        <h3>{{ $t('privacy.features.subtitle_1') }}</h3>
        <div v-html="$t('privacy.features.text_1')" class="consent"></div>

        <h3>{{ $t('privacy.features.subtitle_2') }}</h3>
        <div v-html="$t('privacy.features.text_2')" class="consent"></div>
        <br><a href="javascript:;" @click="showConsent" class="btn--cookie">{{ $t('privacy.features.button') }}</a>

        <h3>{{ $t('privacy.features.subtitle_3') }}</h3>
        <div v-html="$t('privacy.features.text_3')" class="consent"></div>
        <br><a href="javascript:;" @click="showConsent" class="btn--cookie">{{ $t('privacy.features.button') }}</a>
      </div>

      <div class="section">
        <h2>{{ $t('privacy.rights.title') }}</h2>
        <div v-html="$t('privacy.rights.text')" class="consent"></div>
      </div>

      <div class="section">
        <h2>{{ $t('privacy.update.title') }}</h2>
        <div v-html="$t('privacy.update.text')" class="consent"></div>
      </div>
    </section>
  </article>
</template>

<script>
import * as klaro from 'klaro'

export default {
  name: 'Privacy',
  components: {},
  metaInfo() {
    return {
      title: this.$t('privacy.meta_title'),
      meta: [
        { name: 'description', content:  this.$t('global.meta_description') },
        { name: 'keywords', content:  this.$t('global.meta_keywords') }
      ]
    }
  },
  mounted () {
    document.body.classList.add('body--bg-corporate')
  },
  destroyed () {
    document.body.classList.remove('body--bg-corporate')
  },
  methods: {
    showConsent () {
      klaro.show()
    },
  }
}
</script>

<style lang="scss" scoped>
%counter {
  @include desktop {
    width: 100px;
  }

  display: inline-block;
  padding-right: .5rem;
  text-align: right;
}

article {
  counter-reset: step;
}

.section {
  counter-reset: substep;
}

h1 {
  @include font-size(50);

  font-family: $corporate-font-family;
  margin-bottom: 1em;
}

h2 {
  @include desktop {
    text-indent: -100px;
  }

  @include font-size(40);

  font-family: $corporate-font-family;
  left: 0;
  margin-left: 0;
  margin-top: 50px;

  &::before {
    @extend %counter;
    counter-increment: step;
    content: counter(step) '.';

 }
}

h3 {
  @include desktop {
    text-indent: -100px;
  }

  @include font-size(30);
  font-family: $corporate-font-family;

  &::before {
    @extend %counter;
    content: counters(step, '.') '.' counter(substep) '.';
    counter-increment: substep;
  }
}
h5 {
  @include font-size(18);
}

p, a {
  font-family: $body-font-family;
}

.article--bg-corporate .consent ::v-deep *:not(strong) {
  font-family: $body-font-family;
}

</style>
